import "./styles.scss"

import React from "react"

import { useLocale } from "context/locale"

import Section from "components/Section"
import { Person } from "./components"

import { generateSlug } from "utils/generateSlug"

const TeamRow = ({
  id,
  section = { type: undefined, background: undefined, top: true, bottom: true },
  title,
  data,
  category,
}) => {
  const { t } = useLocale()

  const { type, background, top, bottom } = section

  return (
    <Section type={type} background={background} top={top} bottom={bottom}>
      <h2 className="team-row__heading">{title}</h2>
      <div className="team-row__wrapper">
        <div className="row">
          {data
            ?.filter(item => item.acfTeam.category === category)
            .map((item, index) => (
              <div className="col-lg-4 col-sm-6" key={index}>
                <Person
                  id={id ? `${id}_${index + 1}` : undefined}
                  url={`${t("/zespol/")}${generateSlug(item.title)}/`}
                  image={
                    item?.acfTeam?.imageListing?.localFile?.publicURL
                      ? item?.acfTeam?.imageListing?.localFile?.publicURL
                      : item?.acfTeam?.image?.localFile?.publicURL
                  }
                  title={item.title}
                  position={item.acfTeam.position}
                />
              </div>
            ))}
        </div>
      </div>
    </Section>
  )
}

export default TeamRow
