import React from "react"

import { useLocale } from "context/locale"

import TeamRow from "components/TeamRow"

const Others = ({ data }) => {
  const { t } = useLocale()

  return (
    <TeamRow title={t("Pozostali prawnicy")} data={data} category="restlaw" />
  )
}

export default Others
