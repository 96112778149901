exports.generateSlug = string => {
  if (!string) return ""

  const polishCharactersMap = {
    ą: "a",
    ć: "c",
    ę: "e",
    ł: "l",
    ń: "n",
    ó: "o",
    ś: "s",
    ż: "z",
    ź: "z",
    Ą: "A",
    Ć: "C",
    Ę: "E",
    Ł: "L",
    Ń: "N",
    Ó: "O",
    Ś: "S",
    Ż: "Z",
    Ź: "Z",
  }

  return string
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, char => polishCharactersMap[char] || char)
    .replace(/\s+/g, "-")
    .replace(/--+/g, "-")
    .slice(0, 50)
}
