import React from "react"

import { useLocale } from "context/locale"

import TeamRow from "components/TeamRow"

const Partners = ({ data }) => {
  const { t } = useLocale()

  return (
    <TeamRow
      id="partners"
      section={{
        type: "2",
        top: false,
        bottom: true,
      }}
      title={t("Wspólnicy")}
      data={data}
      category="partnerzy"
    />
  )
}

export default Partners
