import "./styles.scss"

import React from "react"

import Section from "components/Section"

const SingleContent = ({ data }) => {
  return (
    <Section className="team-single-content" top={false}>
      <div className="row team-single-content__row">
        <div className="col-xl-4 col-lg-5 offset-xl-1">
          <div
            dangerouslySetInnerHTML={{
              __html: data.data.acfTeam.description,
            }}
          />
        </div>
        <div className="col-xl-6 col-lg-6 offset-lg-1 offset-xl-0">
          <div className="team-single-content__image">
            <img
              src={
                data?.data?.acfTeam?.image?.localFile?.publicURL
                  ? data?.data?.acfTeam?.image?.localFile?.publicURL
                  : data?.data?.acfTeam?.imageListing?.localFile?.publicURL
              }
              alt=""
            />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default SingleContent
