import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"

import SubpageHero from "components/SubpageHero"
import Breadcrumbs from "components/Breadcrumbs"
import SubpageBack from "components/SubpageBack"

import { Partners, Content, Others, OthersLaw } from "page_components/team"

const Team = ({ data, location }) => {
  return (
    <Layout
      location={location}
      seo={{
        title: "Zespół",
      }}
    >
      <SubpageHero title="Zespół" />
      <Breadcrumbs title="Zespół" />
      <Partners data={data?.board?.nodes} />
      <Content data={data?.rest?.nodes} />
      <OthersLaw data={data?.rest?.nodes} />
      <Others data={data?.rest?.nodes} />
      <SubpageBack />
    </Layout>
  )
}

export const query = graphql`
  query {
    board: allWpTeam(
      sort: { order: ASC, fields: date }
      filter: { language: { code: { eq: PL } } }
    ) {
      nodes {
        id
        title
        acfTeam {
          category
          description
          position
          image {
            localFile {
              publicURL
            }
          }
          imageListing {
            localFile {
              publicURL
            }
          }
        }
      }
    }
    rest: allWpTeam(
      sort: { order: ASC, fields: date }
      filter: { language: { code: { eq: PL } } }
    ) {
      nodes {
        id
        title
        acfTeam {
          category
          description
          position
          image {
            localFile {
              publicURL
            }
          }
          imageListing {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default Team
