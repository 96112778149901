import "./styles.scss"

import React from "react"
import { Link } from "gatsby"
import { Parallax } from "react-scroll-parallax"

const Person = ({ id, url, image, title, position }) => {
  return (
    <Link to={url} className="person" data-id={id}>
      <div className="person__image">
        <Parallax translateY={id ? [-15, 15] : undefined}>
          <div
            className="person__image-holder"
            style={{
              backgroundImage: `url('${image}')`,
            }}
          />
        </Parallax>
      </div>
      <h4>{title}</h4>
      <p>{position}</p>
    </Link>
  )
}

export default Person
