import React from "react"

import { useLocale } from "context/locale"

import TeamRow from "components/TeamRow"

const Others = ({ data }) => {
  const { t } = useLocale()

  return (
    <TeamRow
      section={{
        background: "gray",
        top: true,
        bottom: true,
      }}
      title={t("Pozostali pracownicy")}
      data={data}
      category="rest"
    />
  )
}

export default Others
